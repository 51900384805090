<template>
    <div>
        <div>
            <navbar />
        </div>
        <!-- 中间内容 -->
        <div class="middle">
            <div class="middle-head">
                <div class="head-left">
                    <span class="left" @click="goOnlineExpert">Live</span>
                    <span><svg-icon icon="arrow-right"></svg-icon></span>
                    <span class="left-frite">Privacy Policy</span>
                </div>
                <!-- <div class="head-right">
                   
                    <el-input v-model="state.searchValue" class="search-input" :suffix-icon="Search"
                        placeholder="Search for experts and categories" @change="getSearch(state.searchValue)" />
                </div> -->
            </div>
            <div class="content">
                <div class="content-head">
                    <h2>Privacy Policy</h2>
                    <p>Last modification date: April 20th, 2023</p>
                </div>

                <div class="content-box">
                    <h4>Directory</h4>
                    <ul class="faq-list">
                        <li>Content</li>
                        <li>Services and warranties</li>
                        <li>Principles</li>
                        <li>Subscribers</li>
                        <li>Fees, Credit Compensation, Refund and Unlawful Conduct</li>
                        <li>Subscriber's and/or Guest's Declarations</li>
                        <li>Subscriber's and/or Guest's Duties</li>
                        <li>Subscription cancellation</li>
                        <li>Personal Data and Privacy Policy</li>
                        <li>Functionality and Security</li>
                        <li>Spam</li>
                        <li>Contacts</li>
                        <li>Miscellaneous</li>
                    </ul>
                    <h5>1. Clause: ZESTWISDOM - Content</h5>
                    <p>The terms of this agreement ("this agreement") shall govern me/you (i.e. the customer and/or member).
                    </p>
                    <h6>project definition</h6>
                    <ul class="list-category">
                        <li>
                            Point Balance - At ZESTWISDOM, members have the opportunity to purchase point packs to
                            earn points for point appreciation. Our payment processor ensures payment security, and the
                            purchased points will be credited to the member's point balance account accordingly. </li>
                        <li>
                            Guest - A visitor who enters or visits the ZESTWISDOM website but has not registered
                            with the website.
                        </li>
                    </ul>
                    <h6>After entering the website and/or registering, it proves that you agree and accept the following
                        content:</h6>
                    <ul class="list-category1">
                        <li style="counter-increment:firstLevel;">
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li style="counter-increment:secondLevel;">
                            The online entertainment programs provided on this website are provided by individuals aged 18
                            and above worldwide, who are also Service Providers (SPs)..
                        </li>
                        <li style="counter-increment:secondLevel;">
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                    <h5>2. Terms: ZESTWISDOM - Services and Guarantees</h5>
                    <ul class="list-category1">
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li>
                            Before choosing the desired service, it is important to check the content, prices, and payment
                            methods specifically set for your country (credit/debit card, online checks, etc.) beforehand.
                            This information can be found on the website. </li>
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                    <h5>2. Terms: ZESTWISDOM - Services and Guarantees</h5>
                    <ul class="list-category1">
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li>
                            Before choosing the desired service, it is important to check the content, prices, and payment
                            methods specifically set for your country (credit/debit card, online checks, etc.) beforehand.
                            This information can be found on the website. </li>
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                    <h5>2. Terms: ZESTWISDOM - Services and Guarantees</h5>
                    <ul class="list-category1">
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li>
                            Before choosing the desired service, it is important to check the content, prices, and payment
                            methods specifically set for your country (credit/debit card, online checks, etc.) beforehand.
                            This information can be found on the website. </li>
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                    <h5>2. Terms: ZESTWISDOM - Services and Guarantees</h5>
                    <ul class="list-category1">
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li>
                            Before choosing the desired service, it is important to check the content, prices, and payment
                            methods specifically set for your country (credit/debit card, online checks, etc.) beforehand.
                            This information can be found on the website. </li>
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                    <h5>2. Terms: ZESTWISDOM - Services and Guarantees</h5>
                    <ul class="list-category1">
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                        <li>
                            Before choosing the desired service, it is important to check the content, prices, and payment
                            methods specifically set for your country (credit/debit card, online checks, etc.) beforehand.
                            This information can be found on the website. </li>
                        <li>
                            ZESTWISDOM is an online interactive website that minors are prohibited from accessing.
                        </li>
                    </ul>
                </div>

            </div>

        </div>
        <div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../.././layout/components/Navbar.vue'
import Footer from '../.././layout/components/Footer.vue'
import { useRouter } from 'vue-router'
import { Search } from '@element-plus/icons-vue'
import { reactive, onMounted } from 'vue'
const router = useRouter()

onMounted(() => {
    window.scrollTo(0, 0)
})
//搜索
const getSearch = (val) => {
    console.log(val, 'val');
    router.push('/onlineExpert?q=' + val)
}
const state = reactive({
    searchValue: ''
})
const goOnlineExpert = (() => {
    router.push('/onlineExpert')
})


</script>

<style lang="scss" scoped>
.middle {
    width: 100%;
    // background: #10525e;
        background: linear-gradient(180deg, lightblue, #fff, #fff);
    padding-bottom: 20px;

    .middle-head {
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ffff;
        border-color: rgba(251, 249, 249, 0.1);
        font-size: 12px;

        .head-left {
            margin-left: 10px;
            color: #ffff;
            font-size: 15px;

            .left {
                padding-right: 5px;
                cursor: pointer;
            }

            .left-frite {
                color: #eedf11;
            }
        }

        .head-right {
            margin-right: 8px;
            margin-top: 3px;
            display: flex;

            .search-input {
                width: 300px;
                margin-bottom: 5px;
            }

            :deep(.el-input__wrapper) {
                background-color: transparent !important;
            }

            :deep(.el-input__inner) {
                color: #ffffff;
            }

            span {
                font-size: 20px;
                cursor: pointer;
                margin-left: 8px;
                margin-right: 15px;
                padding-right: 8px;
                border-right: 1px solid rgba(251, 249, 249, 0.1);
            }
        }

    }

    .content {
        width: 900px;
        margin: auto;
        margin-top: 30px;
        background: #02758a;

        .content-head {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 15px;
            background: #075f6e;

            h2 {
                color: #ffffff;
                font-size: 20px;
            }

            p {
                font-size: 12px;
                color: #ffffff;
            }
        }

        .content-box {

            h4 {
                line-height: 40px;
                margin-left: 20px;
                color: #ffffff;
            }

            .faq-list {
                margin-left: 40px;
                margin-bottom: 20px;
                box-sizing: border-box;
                display: block;
                column-count: 2;
                color: #eedf11;

                li {
                    line-height: 30px;
                    // margin: 10px 0;
                    font-size: 12px;
                    list-style: decimal;
                    cursor: pointer;
                }
            }

            h5 {
                line-height: 45px;
                margin-left: 20px;
                color: #ffffff;
            }

            p {
                line-height: 20px;
                margin-left: 20px;
                color: #ffffff;
                font-size: 12px;
            }

            h6 {
                line-height: 30px;
                margin-left: 20px;
                color: #ffffff;
            }

            .list-category {
                margin-left: 40px;

                li {
                    font-size: 12px;
                    color: #ffffff;
                    margin: 10px 0;
                }
            }

            .list-category1 {
                margin-left: 40px;
                box-sizing: border-box;
                display: flex;
                list-style: none;
                flex-direction: column;
                padding-bottom: 10px;


                li {
                    font-size: 12px;
                    color: #ffffff;
                    position: relative;
                    margin: 10px 0;
                    margin-left: 15px;
                    padding: 0 0 0 10px;
                }
            }

            .list-category1 li::before {
                content: counters(firstLevel, "") "." counters(secondLevel, ".") ".";
                position: absolute;
                left: 0px;
                transform: translateX(-100%);
            }
        }
    }
}
</style>